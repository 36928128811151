export const FirebaseConfig = {
	"projectId": "gdelivery-bbc02",
	"appId": "1:139935296561:web:3ded501434bbd3ef00f910",
	"databaseURL": "https://gdelivery-bbc02-default-rtdb.europe-west1.firebasedatabase.app",
	"storageBucket": "gdelivery-bbc02.appspot.com",
	"locationId": "europe-west",
	"apiKey": "AIzaSyB9R3QRZCcTh-hJEqTbe4MaGHKH2rUgNlw",
	"authDomain": "gdelivery-bbc02.firebaseapp.com",
	"messagingSenderId": "139935296561",
	"measurementId": "G-3QPG0MHTBE"
};